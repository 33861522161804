import React, { useEffect, useRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import SocialIcon from "@Components/common/SocialIcon";
import SideMenuButton from "@Components/common/SideMenuButton";
import Headroom from "headroom.js";
import text from "@Lib/texts/header";
import ServicesDropdown from "./ServicesDropdown";
import ServicesMenu from "./ServicesMenu";

export default function Header({ open, setOpen }) {
  const router = useRouter();
  const sidemenuRef = useRef();
  const sidemenuButtonRef = useRef();
  const navbar = useRef();
  let headroom;

  // eslint-disable-next-line
  const handleClickOutside = (e) => {
    if (sidemenuRef && sidemenuRef.current) {
      if (
        !sidemenuRef.current.contains(e.target) &&
        !sidemenuButtonRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    headroom = new Headroom(navbar.current);
    headroom.init();

    if (
      router.pathname === "/terms-and-conditions" ||
      router.pathname === "/privacy-policy"
    ) {
      headroom.freeze();
    } else {
      headroom.unfreeze();
    }
  }, [router]);

  useEffect(() => {
    open
      ? document.body.classList.add("body--sidemenu-opened")
      : document.body.classList.remove("body--sidemenu-opened");
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [open, handleClickOutside]);

  useEffect(() => {
    setOpen(false);
  }, [router, setOpen]);

  return (
    <header>
      <nav className="nav-container" id="navbar-scroll" ref={navbar}>
        <Link href="/" passHref={true}>
          <div className="sas-logo-container">
            <div className="sas-logo" />
            <div className="sas-logo-text" />
          </div>
        </Link>

        <div
          style={{ gap: "2rem", marginLeft: "auto" }}
          className="menu-desktop"
        >
          <Link href="/" passHref={true}>
            <div className="link hover:text-primary transition-all">Home</div>
          </Link>

          <Link href="/about" passHref={true}>
            <div className="link hover:text-primary transition-all">
              About us
            </div>
          </Link>

          <Link href="/work-process" passHref={true}>
            <div className="link hover:text-primary transition-all">
              Work Process
            </div>
          </Link>

          <ServicesMenu />

          <Link href="/project-details" passHref={true}>
            <div className="link hover:text-primary transition-all">
              Projects
            </div>
          </Link>

          <Link href="/contact" passHref={true}>
            <div className="link hover:text-primary transition-all">
              Contact
            </div>
          </Link>
        </div>

        <SideMenuButton
          open={open}
          setOpen={setOpen}
          refTest={sidemenuButtonRef}
        />

        <div
          ref={sidemenuRef}
          className={`side-menu-container bg-gradient-to-t from-secondary to-primary ${
            open ? "side-menu-container--open" : ""
          }`}
        >
          <div className="links-container">
            <Link href="/" passHref={true}>
              <div className="link">Home</div>
            </Link>

            <Link href="/about" passHref={true}>
              <div className="link">About us</div>
            </Link>

            <Link href="/work-process" passHref={true}>
              <div className="link">Work Process</div>
            </Link>

            <ServicesDropdown />

            <Link href="/project-details" passHref={true}>
              <div className="link">Projects</div>
            </Link>

            <Link href="/contact" passHref={true}>
              <div className="link">Contact</div>
            </Link>
          </div>

          <div className="socials-container">
            <div className="text">{text.followUs}</div>
            <div className="menu-icons">
              <SocialIcon type="linkedin" color="#fff" />
              <SocialIcon type="instagram" color="#fff" />
              <SocialIcon
                type="clutch"
                primary={true}
                color="#fff"
                className="absolute top-[calc(50%-15px)] left-[calc(50%-15px)] opacity-30 hover:opacity-100"
              />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
