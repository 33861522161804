import CustomInput from "@Components/common/CustomInput";
import SocialIcon from "@Components/common/SocialIcon";
import React, { useCallback, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

const Footer = () => {
  const text = {
    newsletter: {
      placeholder: "Email",
      default: "Subscribe",
      subscribed: "Subscribed!",
      retry: "Retry",
    },
    detailsSection: {
      name: "SAS SOFTWARE DEVELOPMENT SRL",
      location: "Ilfov, Romania",
      address: "Drum Bisericii 21-23-25 B, Loc. Voluntari",
      cif: "CIF: 43884024",
      regCom: "Reg. Com.: J23/2703/2022",
      postalCode: "Postal code: 020661",
      terms: "Terms of use",
      policy: "Privacy Policy",
      brochure: "Brochure",
      aboutUs: "Our Story",
    },
  };
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState(
    text.newsletter.default
  );
  const [showSpinner, setShowSpinner] = useState(false);

  const handleSubscribe = useCallback(() => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);

      setSubscriptionStatus("red");
      setSubscriptionMessage("Error");
    }, 2000);
  }, [email]);

  return (
    <footer className="footer-container">
      <div className="footer-header inner-section">
        <Link href="/contact" passHref={true}>
          <div className="contact-btn">
            <h1 className="font-[Spartan] text-[28px]">
              Contact{" "}
              <div className="contact-white">
                <span>Contact Us</span>
              </div>
            </h1>
          </div>
        </Link>
        <div className="newsletter">
          <CustomInput
            innerPlaceholder={text.newsletter.placeholder}
            type="email"
            className="input-container"
            boxClass={`newsletter__input newsletter__input__${
              subscriptionStatus === ""
                ? ""
                : subscriptionStatus === "green"
                ? "green"
                : "red"
            }`}
            value={email}
            updateOn={setEmail}
          />
          <button
            className={`newsletter__btn newsletter__btn__${
              subscriptionStatus === ""
                ? ""
                : subscriptionStatus === "green"
                ? "green"
                : "red"
            }`}
            disabled={subscriptionStatus === "green"}
            onClick={handleSubscribe}
          >
            {!showSpinner && subscriptionMessage}
            {showSpinner && <div className="button__spinner" />}
          </button>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-20 gap-y-10 max-w-[1200px]">
          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-header font-bold text-center sm:text-left">
              Company
            </h2>

            <div className="flex flex-col gap-2 font-normal text-center sm:text-left">
              <p>{text.detailsSection.name}</p>
              <p>{text.detailsSection.cif}</p>

              <p>{text.detailsSection.location}</p>
              <p>{text.detailsSection.regCom}</p>

              <p>{text.detailsSection.address}</p>
              <p>{text.detailsSection.postalCode}</p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-header font-bold text-center sm:text-left">
              Web Pages
            </h2>

            <div className="flex flex-col gap-2 font-normal text-center sm:text-left">
              <Link href="/">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Home
                </p>
              </Link>
              <Link href="/about">
                <p className="hover:font-bold hover:text-primary transition-all">
                  About Us
                </p>
              </Link>

              <Link href="/work-process">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Work Process
                </p>
              </Link>
              <Link href="/project-details">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Projects
                </p>
              </Link>
              <Link href="/contact">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Contact
                </p>
              </Link>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-header font-bold text-center sm:text-left">
              Projects
            </h2>

            <div className="flex flex-col gap-2 font-normal text-center sm:text-left">
              <Link href="/project-details/1">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Ders Platform
                </p>
              </Link>
              <Link href="/project-details/2">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Extrase 24
                </p>
              </Link>
              <Link href="/project-details/3">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Arctic Stream Platform
                </p>
              </Link>

              <Link href="/project-details/4">
                <p className="hover:font-bold hover:text-primary transition-all">
                  Pay2Park
                </p>
              </Link>
              <Link href="/project-details/5">
                <p className="hover:font-bold hover:text-primary transition-all">
                CityZen
                </p>
              </Link>
              <Link href="/project-details/6">
                <p className="hover:font-bold hover:text-primary transition-all">
                Treecat
                </p>
              </Link>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-header font-bold text-center sm:text-left">
              Legal
            </h2>

            <div className="flex flex-col gap-2 font-normal text-center sm:text-left">
              <Link href="/terms-and-conditions">
                <p className="hover:font-bold hover:text-primary transition-all">
                  {text.detailsSection.terms}
                </p>
              </Link>
              <Link href="/privacy-policy">
                <p className="hover:font-bold hover:text-primary transition-all">
                  {text.detailsSection.policy}
                </p>
              </Link>
              <Link href="/brochure">
                <p className="hover:font-bold hover:text-primary transition-all">
                  {text.detailsSection.brochure}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {router.pathname !== "/contact" ? (
        <div className="footer-social-top-line">
          <div className="footer-social container mx-auto justify-between py-4">
            <Link href="/" passHref={true}>
              <div className="flex items-center">
                <div className="sas-logo w-[30px] h-[30px] cursor-pointer" />
                <div className="sas-logo-text w-[76px] h-[30px] cursor-pointer" />
              </div>
            </Link>

            <p className="font-normal text-xs text-center">
              © COPYRIGHT SAS TECHNOLOGIES. ALL RIGHTS RESERVED.
            </p>
            <div className="flex justify-around sm:w-1/5 w-full items-center">
              <SocialIcon type="linkedin" primary={true} />
              <SocialIcon type="instagram" primary={true} />
              <SocialIcon
                type="clutch"
                primary={true}
                className="absolute top-[calc(50%-15px)] left-[calc(50%-15px)] opacity-30 hover:opacity-100"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="footer-social-top-line">
          <div className="footer-social container mx-auto justify-between py-4">
            <Link href="/" passHref={true}>
              <div className="flex items-center">
                <div className="sas-logo w-[30px] h-[30px] cursor-pointer" />
                <div className="sas-logo-text w-[76px] h-[30px] cursor-pointer" />
              </div>
            </Link>

            <p className="font-normal text-xs text-center">
              © COPYRIGHT SAS TECHNOLOGIES. ALL RIGHTS RESERVED.
            </p>
          </div>
        </div>
      )}
    </footer>
  );
};
export default Footer;
