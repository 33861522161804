import React from "react";

const Button = React.forwardRef(
  ({ children, className, onClick, type, disabled }, ref) => {
    return (
      <button
        className={`${className} font-bold px-8 py-4 ${
          type === "primary"
            ? "border-2 border-primary text-primary rounded-full hover:bg-gradient-to-tr from-primary to-secondary hover:text-white transition-all"
            : ""
        } ${
          disabled
            ? "pointer-events-none border-gray-400 text-gray-300 hover:bg-transparent"
            : ""
        }`}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
