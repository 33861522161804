import { useEffect, useRef, useState } from "react";

export default function CustomInput({
  type,
  placeholder,
  className = "",
  required,
  updateOn,
  value,
  boxClass,
  innerPlaceholder,
}) {
  const input = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [isFieldFilled, setIsFieldFilled] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    input.current.addEventListener("focus", handleInputFocus);
    input.current.addEventListener("blur", handleInputBlur);

    return () => {
      try {
        // eslint-disable-next-line
        input.current.removeEventListener("focus", handleInputFocus);
        // eslint-disable-next-line
        input.current.removeEventListener("blur", handleInputBlur);
      } catch {}
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    value
      ? (setIsFieldFilled(true), setIsFocused(true))
      : setIsFieldFilled(false);
  }, [value]);

  function handleInputFocus() {
    setIsFocused(true);
  }

  function handleInputChange() {
    updateOn ? updateOn(input.current.value) : null;
  }

  function handleInputBlur() {
    if (input) {
      if (input.current.value === "") {
        setIsFocused(false);
        setIsFieldFilled(false);
      } else {
        setIsFieldFilled(true);
      }
      updateOn ? updateOn(input.current.value) : null;
    }

    required ? checkInput() : null;
  }

  function checkInput() {
    if (type === "email") {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(input.current.value) === false) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else if (type === "phone") {
      const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (regex.test(input.current.value) === false) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else if (input.current.value === "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }

  return (
    <div className={`custom-input ${className}`}>
      <label
        className={`custom-input__label ${
          isFocused ? "custom-input__label--focused" : ""
        }`}
        htmlFor={placeholder}
      >
        {placeholder}
        {required ? "*" : ""}
      </label>
      <input
        id={placeholder}
        className={`custom-input__box ${
          isFieldFilled ? "custom-input__box--filled" : ""
        } ${boxClass}`}
        type={type}
        ref={input}
        value={value}
        onChange={() => handleInputChange()}
        placeholder={innerPlaceholder}
      />
      {required ? (
        <label
          className={`custom-input__error ${
            showError ? "custom-input__error--show" : ""
          }`}
        >
          {type === "email"
            ? "*Email is not valid!"
            : type === "phone"
            ? "*Phone number is not valid"
            : "*Field required!"}
        </label>
      ) : null}
    </div>
  );
}
