const SideMenuButton = ({ open, setOpen, refTest, className }) => {
  return (
    <div
      ref={refTest}
      onClick={() => setOpen(!open)}
      className={`closeButton ${open ? "cross" : null} ${className}`}
    >
      <svg className="icon" viewBox="-40 0 900 600">
        <path
          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
          className="path top"
        ></path>
        <path d="M200,320 L540,320" className="path middle"></path>
        <path
          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
          className="path bottom"
        ></path>
      </svg>
    </div>
  );
};
export default SideMenuButton;
