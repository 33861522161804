export const seoData = [
  {
    route: "/",
    title: "Home | SAS Technologies",
    meta: {
      title: "SAS-Technologies Custom Software Development | SAS Technologies",
      description:
        "From product design to software continuous delivery, we develop custom IT solutions for all industries",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/404",
    title: "Not Found | SAS Technologies",
    meta: {
      title: "Not Found | SAS Technologies",
      description: "Page not found from our servers",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/500",
    title: "Bad Request | SAS Technologies",
    meta: {
      title: "Bad Request | SAS Technologies",
      description: "Oups! Something went wrong on our servers",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/services",
    title: "Custom Software Development Services Company | SAS Technologies",
    meta: {
      title: "Custom Software Development Services Company | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/services/0",
    title: "Cloud integration | SAS Technologies",
    meta: {
      title: "Cloud integration | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/services/1",
    title: "Mobile Development | SAS Technologies",
    meta: {
      title: "Mobile Development | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/services/2",
    title: "Post-Development Maintenance | SAS Technologies",
    meta: {
      title: "Post-Development Maintenance | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/services/3",
    title: "Product Design | SAS Technologies",
    meta: {
      title: "Product Design | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/services/4",
    title: "Web development | SAS Technologies",
    meta: {
      title: "Web development | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/countdown",
    title: "Cowntdown, here we come to make a change | SAS Technologies",
    meta: {
      title: "Cowntdown, here we come to make a change | SAS Technologies",
      description: "Stay tuned for our release! SAS Technologies",
      keywords:
        "Web, development, mobile, product, design, IT, Create, Efficient, IOS, Andorid, Cross-platform, Vital, Business, metrics, UX, UI, Applications, custom, design, interfaces, scale, ship, build, Projects, tailored, Ideation, Cloud, migration, AI, Cybersecurity, cyber, security, Contact, SAS, Technologies",
    },
  },
  {
    route: "/about",
    title: "About Us | SAS Technologies",
    meta: {
      title: "About Us and Our Approach | SAS Technologies",
      description:
        "Young and open-minded team of developers focused on providing software solutions for your tech challenges",
      keywords:
        "Consultation, Digital, Transformation, Visionary, unique, team, who, help, Senior, Developers, projects, experience, business, growth, long-term, benefits, work, SAS, Technologies, Partner, grow, fresh, client, flexible, young, communication, software, open-minded, success, design, ideation, kickoff, begin, journey",
    },
  },
  {
    route: "/project-details",
    title: "Our Projects | SAS Technologies",
    meta: {
      title: "Check our projects | SAS Technologies",
      description:
        "We will lead you through the process of transforming a vision into a scalable concept",
      keywords:
        "Web, development, world-class, digital, UI/UX, services, scale, business, IOS, develop, top-shelf, applications, technologies, transform, innovation, digital, product, android, craft, scalable, cutting-edge, idea, penetration, testing, issues, noncompliance, security, program, safe, environment, data,  developers, team, designers, cultures, united, fresh, benefits, young, solutions, project, client-oriented, dream-team, software, tailored, quality, improvements, work, experience, perseverance, ambition, values, core, successful, company, all-around, mistakes, knowledge, gain, shaping, vision, world-class, step, excellent, SAS-Technologies, impact, ability, high-pace, focus, lead, transforming, sketched, main, features, concept, brainstorm, personalize, user, high-fidelity, prototype, stage, major, functionality, stretched, final, touches, back-end, front-end, responsive, process, Q/A, specialists, adjustments, deployment, transition, platform",
    },
  },
  {
    route: "/project-details/1",
    title: "DERS - Complex Event Management Platform | SAS Technologies",
    meta: {
      title: "DERS - Complex Event Management Platform | SAS Technologies",
      description:
        "Leverage the efficiency of DERS, an all-inclusive event management platform, engineered to streamline your event planning process. Offering diverse functionalities for event creation, management, and enhancement, DERS ensures an immersive and seamless experience for your attendees.",
      keywords:
        "DERS Event Management, Streamlined Event Planning, Event Creation Tool, Event Management Software, Seamless Attendee Experience, Event Enhancement Features, Comprehensive Event Platform, Engaging Event Solutions, Manage Events Easily, Event Planning Efficiency, Immersive Event Experience, DERS Event Planning, Event Organization Platform, DERS Software, Optimized Event Process",
    },
  },
  {
    route: "/project-details/2",
    title: "Extrase 24 - Efficient Cadastral Solutions | SAS Technologies",
    meta: {
      title: "Extrase 24 - Efficient Cadastral Solutions | SAS Technologies",
      description:
        "Extrase 24, developed by SAS-Technologies, is a web application designed for seamless generation of cadastral documents. Obtain land registry certificates and title extracts swiftly and securely with this efficient solution. With its user-friendly interface, automated processes, and customizable options, Extrase 24 ensures a convenient and reliable document retrieval experience. Simplify your cadastral document needs with Extrase 24 today.",
      keywords:
        "Extrase 24, Cadastral documents, Land registry certificates, Title extracts, SAS-Technologies, Web application, Document generation, Quick and easy, Efficient solution, Secure document retrieval, Intuitive interface, Automated processes, Customization options, Convenient and reliable, Simplify cadastral documents",
    },
  },
  {
    route: "/project-details/3",
    title: "Arctic Stream Platform | SAS Technologies",
    meta: {
      title: "Arctic Stream Platform | SAS Technologies",
      description:
        "SAS Technologies has engineered the innovative Arctic Stream Platform, a feature-rich presentation website. It integrates diverse functionalities such as blog posting, media sharing, interactive courses, subscription management, and automated email capabilities to create a comprehensive online solution.",
      keywords:
        "Arctic Stream Platform, SAS Technologies, Presentation Website, Integrated Blogging, Media Sharing, Online Courses, Subscription Management, Automated Emails, Comprehensive Web Solution, Feature-rich Website, Digital Learning Platform, Email Automation, Interactive Courses, Web-based Subscriptions, Multifunctional Website Platform",
    },
  },
  {
    route: "/project-details/4",
    title: "Pay2Park - Simplify Your Parking Experience | SAS Technologies",
    meta: {
      title: "Pay2Park - Simplify Your Parking Experience | SAS Technologies",
      description:
        "Pay2Park is a game-changing mobile app that transforms the way parking payments are made. Seamlessly integrating with Genetec, it provides users with flexible payment options, ensuring a convenient and efficient parking experience. Say goodbye to the hassle of traditional payment methods and embrace the future of parking with Pay2Park",
      keywords:
        "Pay2Park, mobile app, parking payments, Genetec integration, flexible payment options, convenient parking experience, efficient parking payments, seamless parking app, revolutionizing parking payments, hassle-free parking transactions, future of parking technology, Genetec-powered parking app, streamlined parking payment solution, easy and secure parking payments, enhance your parking experience with Pay2Park",
    },
  },
  {
    route: "/contact",
    title: "Contact | SAS Technologies",
    meta: {
      title: "Book a Meeting With Us | SAS Technologies",
      description:
        "SAS Technologies is committed to processing the above information in order to contact you and talk about your project",
      keywords:
        "book, meeting, contact, us, email, respond, enquiries, select, date, choose, know, who, web, development, mobile, app, AI, cloud, migration, product, design, body, leasing, ideation, cyber, security, tell, details, topics, name, gdpr, SAS, Technologies",
    },
  },
  {
    route: "/blog",
    title: "Check Our Latest Blogs | SAS Technologies",
    meta: {
      title: "Check Our Latest Blogs | SAS Technologies",
      description:
        "Opinions, tips, and latest news on design, technology, software, and business for innovators like you.",
      keywords:
        "check, opinions, tips, software, design, business, innovators, UX/UI, Project, management, innovation, software, development, SAS, Technologies, growth, way, industry, insights, people, culture, SAS, Technologies",
    },
  },
  {
    route: "/blog/posts/9c762878-afc1-4958-9c82-1cd89ba0deb6",
    title: "Blog: Meet Our Expert Team | SAS Technologies",
    meta: {
      title: "Blog: Meet Our Expert Team | SAS Technologies",
      description:
        "We are all enthusiasts of our field focused on one main goal, being better than yesterday and weaker than tomorrow",
      keywords:
        "who, we, group, cultures, visions, well-structured, work, machine, SAS, Technologies, innovative, direction, enthusiasts, field, better, yesterday, weaker, tomorrow, motto, timeline, belief, rule, search, secret, formula, extraterrestrial, environment, cool, different, people, problem, family, rock, climbing, music, production, tattooing, video, games, united",
    },
  },
  {
    route: "/blog/posts/2bbeab96-7cda-4401-b2c5-ddde80b622c7",
    title: "Blog: Flutter VS React Native | SAS Technologies",
    meta: {
      title: "Blog: Flutter VS React Native | SAS Technologies",
      description:
        "In comparison React Native vs. Flutter, the last one is better in terms of performance, compatibility & app features, engineering cost, and further market trends.",
      keywords:
        "fast, development, process, flutter, codes, different, IOS, Android, applications, UI, operating, system, widget, libraries, multiple, device, support, user, interface, features, smartphone, processors, uniformity, products, faster, code, writing, implementation, dynamic, flexible, platform, frameworks, unique, hot, reload, direct, impact, instantly, pros, cons, lack, newer, open, source, pre-built, create, large, file, sizes, enormous, manage, community, open-source, framework, grow, reusability, handy, functions, third-party, consisting, complex, complexity, tested, buggy, difficult, React, Native, newbies, experience, career, poor, hence, analytical, security, drawbacks, transactions, banking, performance, compatibility, engineering, market, trends, skilled, engineers, vs",
    },
  },
  {
    route: "/blog/posts/d50fb65e-cf88-4fb6-8c0b-e21b7a451e4e",
    title: "Blog: Adobe XD vs Figma vs Sketch | SAS Technologies",
    meta: {
      title: "Blog: Adobe XD vs Figma vs Sketch | SAS Technologies",
      description:
        "Adobe XD vs. Figma vs. Sketch You’ve heard of all three of them, and now you do not know which one’s better",
      keywords:
        "Adobexd, figma, sketch, better, UX, designer, design, software, vs, debate, wireframing, tool, prototyping, developed, bohemian, coding, vector, graphics, editor, macos, apple, award, UX/UI, key, features, preview, grids, guidelines, preset, exporting, plugin, support, collaborative, platform, pros, cons, advantages, scalable, produce, users, reusable, virtual, system, libraries, reiteration, efficiency, pixel-perfect, automation, accurate, renderings, free, version, windows, capabilities, editing, retouching, built-in, functionalities, hand-off, third-party, cloud, collaborating, end-to-end, real-time, cloud-based, desktop, collaboration, Mirror, multi-plugins, modern, build, mac, resources, performance, solutions, browser, transparency, file, online, colors, mobile, auto-animation, creating, infrastructure, resizing, gaming, mobile-flows, web-flows, flows, css, photoshop, user-experience, creative-cloud, usability, debugging, quality, co-editing, specific-design, IOS, Android, Native, responsive, growth",
    },
  },
  {
    route: "/blog/posts/2a76d895-1961-4c70-97ae-eb6d819638f5",
    title: "Blog: Flutter VS React Native | SAS Technologies",
    meta: {
      title: "Blog: Flutter VS React Native | SAS Technologies",
      description:
        "In comparison React Native vs. Flutter, the last one is better in terms of performance, compatibility & app features, engineering cost, and further market trends.",
      keywords:
        "fast, development, process, flutter, codes, different, IOS, Android, applications, UI, operating, system, widget, libraries, multiple, device, support, user, interface, features, smartphone, processors, uniformity, products, faster, code, writing, implementation, dynamic, flexible, platform, frameworks, unique, hot, reload, direct, impact, instantly, pros, cons, lack, newer, open, source, pre-built, create, large, file, sizes, enormous, manage, community, open-source, framework, grow, reusability, handy, functions, third-party, consisting, complex, complexity, tested, buggy, difficult, React, Native, newbies, experience, career, poor, hence, analytical, security, drawbacks, transactions, banking, performance, compatibility, engineering, market, trends, skilled, engineers, vs",
    },
  },
];
