import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React from "react";
import { seoData } from "./SeoData";
import text from "@Lib/texts/head-titles";

const HeadScripts = () => {
  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NDNNCCX');
        `}
      </Script>

      <script
        dangerouslySetInnerHTML={{
          __html: `
      _linkedin_partner_id = "3517882";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
     (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
    `,
        }}
      />
    </>
  );
};

const HeadFonts = () => {
  const fontsArr = [
    [
      "Rubik/Rubik-Light.ttf",
      "Rubik/Rubik-LightItalic.ttf",
      "Rubik/Rubik-Regular.ttf",
      "Rubik/Rubik-Italic.ttf",
      "Rubik/Rubik-Medium.ttf",
      "Rubik/Rubik-MediumItalic.ttf",
      "Rubik/Rubik-SemiBold.ttf",
      "Rubik/Rubik-SemiBoldItalic.ttf",
      "Rubik/Rubik-Bold.ttf",
      "Rubik/Rubik-BoldItalic.ttf",
      "Rubik/Rubik-ExtraBold.ttf",
      "Rubik/Rubik-ExtraBoldItalic.ttf",
      "Rubik/Rubik-Black.ttf",
      "Rubik/Rubik-BlackItalic.ttf",
    ],
    [
      "Spartan/Spartan-Thin.ttf",
      "Spartan/Spartan-ExtraLight.ttf",
      "Spartan/Spartan-Light.ttf",
      "Spartan/Spartan-Regular.ttf",
      "Spartan/Spartan-Medium.ttf",
      "Spartan/Spartan-SemiBold.ttf",
      "Spartan/Spartan-Bold.ttf",
      "Spartan/Spartan-ExtraBold.ttf",
      "Spartan/Spartan-Black.ttf",
    ],
  ];
  return (
    <>
      {fontsArr.map((fontData, idx) => {
        fontData.map((font, idy) => (
          <link
            key={`${idx}-${idy}`}
            rel="preload"
            href={font}
            as="font"
            crossOrigin=""
            type="font/ttf"
          />
        ));
      })}
    </>
  );
};

const HeadGenerator = ({ error = false, isFallback = false }) => {
  const { asPath } = useRouter();
  const hostUrl = process.env.NEXT_PUBLIC_LOCALHOST;
  const imagesUrl = "/assets/images";
  const hostLanguage = process.env.NEXT_PUBLIC_LOCALHOST_LANGUAGE;

  const cleanString = (str) => {
    return str
      .replace(/[^\w\s]/g, " ")
      .split(" ")
      .filter((item) => item !== "")
      .map((item) => item.replace(/./, (c) => c.toUpperCase()))
      .join(" ");
  };

  const seoGeneraor = () => {
    if (error) {
      return (
        <>
          <title>{text.error}</title>
          <meta name="robots" content="noindex, nofollow" />
        </>
      );
    }

    if (isFallback) {
      return (
        <>
          <title>{text.fallback}</title>
          <meta name="robots" content="noindex, nofollow" />
        </>
      );
    }
    console.log(asPath);
    const seoContent = seoData.filter((seoObj) => seoObj.route == asPath)[0];

    if (seoContent) {
      return (
        <>
          <title>{seoContent.title}</title>
          <meta name="title" content={seoContent.meta.title} />
          <meta name="description" content={seoContent.meta.description} />
          <meta name="keywords" content={seoContent.meta.keywords} />
          <meta property="og:title" content={seoContent.meta.title} />
          <meta
            property="og:description"
            content={seoContent.meta.description}
          />

          <meta name="robots" content="index, follow" />
        </>
      );
    } else {
      return (
        <>
          <title>{`${cleanString(asPath)} | SAS Technologies`}</title>
          <meta name="title" content="Not Found | SAS Technologies" />
          <meta
            name="description"
            content={`${cleanString(asPath)} | SAS Technologies`}
          />
          <meta
            property="og:title"
            content={`${cleanString(asPath)} | SAS Technologies`}
          />
          <meta
            property="og:description"
            content={`${cleanString(asPath)} | SAS Technologies`}
          />
          <meta name="robots" content="noindex, nofollow" />
        </>
      );
    }
  };

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />
      <link rel="canonical" href={`${hostUrl}${asPath}`} />
      {seoGeneraor()}
      <meta httpEquiv="content-language" content={`${hostLanguage}`} />
      <meta property="og:locale" content={`${hostLanguage}`} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={`${imagesUrl}/android-chrome-512x512.png`}
      />
      <meta property="og:url" content={`${hostUrl}${asPath}`} />
      <meta property="og:site_name" content="SAS Technologies" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${imagesUrl}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${imagesUrl}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${imagesUrl}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${hostUrl}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${imagesUrl}/safari-pinned-tab.svg`}
        color="#dc8bf7"
      />
      <meta name="msapplication-TileColor" content="#dc8bf7" />
      <meta
        name="msapplication-TileImage"
        content={`${imagesUrl}/favicon-32x32.png`}
      />
      <meta name="theme-color" content="#ffffff" />
      <HeadFonts />
      <HeadScripts />
    </Head>
  );
};

export default HeadGenerator;
