import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useWindowDimensions } from "@Services/contexts/WindowDimensionsContext";

const CirclesBackground = () => {
  const { asPath, query, pathname } = useRouter();
  const [numberOfCircles, setNumberOfCircles] = useState();
  const [color, setColor] = useState("#fe75fe");
  const { pageHeight, isMobile } = useWindowDimensions();

  const circleBackgroundsGenerator = useCallback(() => {
    const pageCirclesNumber = Math.floor(pageHeight / 500);
    setNumberOfCircles(pageCirclesNumber);
  }, [pageHeight]);

  useEffect(() => {
    if (pageHeight) {
      circleBackgroundsGenerator();
    }
  }, [circleBackgroundsGenerator, pageHeight]);

  useEffect(() => {
    if (pathname !== "/project-details/[id]") {
      setColor("#fe75fe");
      return;
    }

    switch ("query.id") {
      case "1":
        setColor("#144DAE");
        break;
      case "2":
        setColor("#994DAE");
        break;
      case "3":
        setColor("#14ae28");
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  if (!isMobile) {
    return (
      <div
        className="global-circles-background-wrapper"
        style={{ height: pageHeight + "px" }}
      >
        <div className="global-circles-background">
          {numberOfCircles &&
            [...Array(numberOfCircles)].map((_, idx) => {
              return (
                <div
                  key={idx}
                  className="circle-bg"
                  style={{
                    backgroundColor: color,
                    top:
                      idx == 0
                        ? "calc(0% - 250px)"
                        : idx % 2 == 0
                        ? idx * 550
                        : idx * 550,
                    left:
                      idx == 0
                        ? "15%"
                        : idx % 2 == 0
                        ? "calc(0% - 230px)"
                        : "calc(100% - 270px)",
                  }}
                />
              );
            })}
        </div>
      </div>
    );
  }

  return null;
};

export default CirclesBackground;
