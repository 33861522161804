import { useEffect, useRef } from "react";

export default function Modal({
  children,
  showModal,
  setShowModal,
  containerClass,
  bodyClass,
  setSelectedDay,
  setSelectedHour,
  showEsc = true,
  preventOutsideClick = false,
}) {
  const modal = useRef();

  useEffect(() => {
    window.addEventListener("click", handleModalClick);

    return () => {
      window.removeEventListener("click", handleModalClick);
    };
    // eslint-disable-next-line
  }, []);

  function handleModalClick(e) {
    if (e.target === modal.current) {
      setShowModal(false);
      setSelectedHour ? setSelectedHour(null) : null;
      setSelectedDay ? setSelectedDay(null) : null;
    }
  }

  function closeModal() {
    setSelectedHour ? setSelectedHour(null) : null;
    setSelectedDay ? setSelectedDay(null) : null;
    setShowModal(false);
  }

  return (
    <div
      ref={modal}
      className={`modal-container ${containerClass} ${
        showModal ? "" : "modal-container--hide"
      }`}
    >
      <div
        className={`modal-body ${bodyClass} ${
          showModal ? "modal-body--show" : "modal-body--hide"
        }`}
      >
        {showEsc ? (
          <div
            className="modal-body__close-btn"
            onClick={() => closeModal()}
          ></div>
        ) : null}
        <div className="modal-body__content">{children}</div>
      </div>
    </div>
  );
}
