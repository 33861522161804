import Link from "next/link";
import Button from "./common/ButtonCustom";
import text from "@Lib/texts/cookies";
import parse from "html-react-parser";

export default function CookieMsg({ className, setShowModal }) {
  return (
    <div className={`cookie-msg-container ${className}`}>
      <h1>{parse(text.title)}</h1>
      <p>
        {text.desc}
        <span>
          <Link href="/privacy-policy">{text.learnMore}</Link>
        </span>
      </p>

      <div className="btns">
        <Button
          type="secondary"
          onClick={() => setShowModal(false)}
          className="btn-left"
        >
          {text.declineBtn}
        </Button>
        <Button
          type="primary"
          onClick={() => setShowModal(true)}
          className="btn-right"
        >
          {text.allowBtn}
        </Button>
      </div>
    </div>
  );
}
