const SocialIcon = ({ type = "facebook", color = "#000", primary = false, className }) => {
  const icons = {
    facebook: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="20"
          viewBox="0 0 9.687 18.086"
          className="facebookIcon"
        >
          <path
            id="Icon_awesome-facebook-f"
            data-name="Icon awesome-facebook-f"
            d="M10.661,10.173l.5-3.273H8.023V4.776A1.637,1.637,0,0,1,9.868,3.008H11.3V.221A17.412,17.412,0,0,0,8.762,0C6.175,0,4.484,1.568,4.484,4.406V6.9H1.609v3.273H4.484v7.913H8.023V10.173Z"
            transform="translate(-1.609)"
            className="socialIconPath"
            style={{ fill: color }}
          />
        </svg>
      ),
      link: "https://facebook.com",
    },
    clutch: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30.667"
          height="30.667"
          version="1"
          transform="translate(-1.609)"
          className={className}
          viewBox="0 0 32 32"
        >
          <path
            d="M101 282c-19-10-43-34-53-53-66-124 77-252 196-174l27 18-20 21c-18 17-24 19-41 8-52-32-120 4-120 64 0 58 69 93 120 62 17-11 23-9 41 8l20 21-27 18c-41 27-102 30-143 7z"
            transform="matrix(.1 0 0 -.1 0 32)"
            style={{ fill: color }}
          ></path>
          <path
            d="M136 194c-20-20-20-38 0-59 20-20 42-16 60 10 12 18 11 24-2 43-18 26-37 28-58 6z"
            transform="matrix(.1 0 0 -.1 0 32)"
            style={{ fill: color }}
          ></path>
        </svg>
      ),
      link: "https://clutch.co/profile/sas-technologies-0#highlights",
    },
    instagram: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 17.475 17.471"
          className="squareIcon"
        >
          <path
            id="Icon_awesome-instagram"
            data-name="Icon awesome-instagram"
            d="M8.734,6.494a4.479,4.479,0,1,0,4.479,4.479A4.472,4.472,0,0,0,8.734,6.494Zm0,7.392a2.912,2.912,0,1,1,2.912-2.912,2.917,2.917,0,0,1-2.912,2.912Zm5.707-7.575A1.045,1.045,0,1,1,13.4,5.266,1.042,1.042,0,0,1,14.442,6.311Zm2.967,1.06A5.17,5.17,0,0,0,16,3.71,5.2,5.2,0,0,0,12.336,2.3c-1.442-.082-5.766-.082-7.208,0A5.2,5.2,0,0,0,1.467,3.706,5.187,5.187,0,0,0,.056,7.367c-.082,1.442-.082,5.766,0,7.208a5.17,5.17,0,0,0,1.411,3.661,5.211,5.211,0,0,0,3.661,1.411c1.442.082,5.766.082,7.208,0A5.17,5.17,0,0,0,16,18.236a5.2,5.2,0,0,0,1.411-3.661c.082-1.442.082-5.762,0-7.2Zm-1.863,8.752a2.948,2.948,0,0,1-1.661,1.661c-1.15.456-3.879.351-5.15.351s-4,.1-5.15-.351a2.948,2.948,0,0,1-1.661-1.661c-.456-1.15-.351-3.879-.351-5.15s-.1-4,.351-5.15A2.948,2.948,0,0,1,3.584,4.163c1.15-.456,3.879-.351,5.15-.351s4-.1,5.15.351a2.948,2.948,0,0,1,1.661,1.661c.456,1.15.351,3.879.351,5.15S16,14.977,15.545,16.123Z"
            transform="translate(0.005 -2.238)"
            className="socialIconPath"
            style={{ fill: color }}
          />
        </svg>
      ),
      link: "https://instagram.com/sas_technologies.ro?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D",
    },
    twitter: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.96"
          height="16.211"
          viewBox="0 0 19.96 16.211"
          className="squareIcon"
        >
          <path
            id="Icon_awesome-twitter"
            data-name="Icon awesome-twitter"
            d="M17.909,7.421c.013.177.013.355.013.532A11.56,11.56,0,0,1,6.282,19.592,11.56,11.56,0,0,1,0,17.756a8.463,8.463,0,0,0,.988.051,8.193,8.193,0,0,0,5.079-1.748,4.1,4.1,0,0,1-3.825-2.837,5.159,5.159,0,0,0,.773.063,4.327,4.327,0,0,0,1.077-.139A4.091,4.091,0,0,1,.811,9.131V9.08A4.12,4.12,0,0,0,2.66,9.6,4.1,4.1,0,0,1,1.393,4.128,11.628,11.628,0,0,0,9.828,8.409a4.618,4.618,0,0,1-.1-.937,4.095,4.095,0,0,1,7.08-2.8,8.054,8.054,0,0,0,2.6-.988,4.08,4.08,0,0,1-1.8,2.254,8.2,8.2,0,0,0,2.356-.633,8.794,8.794,0,0,1-2.052,2.115Z"
            transform="translate(0 -3.381)"
            className="socialIconPath"
            style={{ fill: color }}
          />
        </svg>
      ),
      link: "https://twitter.com",
    },
    dribbble: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 19.337 19.337"
          className="squareIcon"
        >
          <path
            id="Icon_awesome-dribbble"
            data-name="Icon awesome-dribbble"
            d="M10.231.563A9.668,9.668,0,1,0,19.9,10.231,9.679,9.679,0,0,0,10.231.563Zm6.392,4.459a8.219,8.219,0,0,1,1.865,5.144,19.3,19.3,0,0,0-5.75-.266c-.224-.547-.436-1.029-.726-1.622A11,11,0,0,0,16.623,5.021Zm-.918-.955a9.324,9.324,0,0,1-4.328,2.983A44.08,44.08,0,0,0,8.3,2.215a8.237,8.237,0,0,1,7.41,1.851ZM6.72,2.77A52.693,52.693,0,0,1,9.782,7.546,30.8,30.8,0,0,1,2.147,8.552,8.288,8.288,0,0,1,6.72,2.77ZM1.973,10.243c0-.084,0-.168,0-.252a30.463,30.463,0,0,0,8.487-1.175c.236.463.462.932.67,1.4A12.791,12.791,0,0,0,4.1,15.765,8.225,8.225,0,0,1,1.973,10.243Zm3.189,6.515a11.615,11.615,0,0,1,6.533-5.175,34.327,34.327,0,0,1,1.762,6.262,8.241,8.241,0,0,1-8.295-1.087Zm9.683.331a35.643,35.643,0,0,0-1.6-5.888,12.121,12.121,0,0,1,5.144.353A8.273,8.273,0,0,1,14.845,17.089Z"
            transform="translate(-0.563 -0.563)"
            className="socialIconPath"
            style={{ fill: color }}
          />
        </svg>
      ),
      link: "https://ball.com",
    },
    linkedin: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 17.465 17.465"
          className="squareIcon"
        >
          <path
            id="Icon_awesome-linkedin-in"
            data-name="Icon awesome-linkedin-in"
            d="M3.909,17.466H.288V5.805H3.909ZM2.1,4.215A2.107,2.107,0,1,1,4.194,2.1,2.115,2.115,0,0,1,2.1,4.215ZM17.461,17.466H13.848V11.789c0-1.353-.027-3.088-1.883-3.088-1.883,0-2.171,1.47-2.171,2.99v5.774H6.178V5.805H9.65V7.4H9.7a3.8,3.8,0,0,1,3.426-1.883c3.665,0,4.338,2.413,4.338,5.548v6.405Z"
            transform="translate(0 -0.001)"
            className="socialIconPath"
            style={{ fill: color }}
          />
        </svg>
      ),
      link: "https://www.linkedin.com/company/sas-technologies-dev/",
    },
  };
  return (
    <a
      className={`socialIconContainer ${
        primary ? "socialIconContainer-primary" : ""
      }`}
      href={icons[type].link}
      target="_blank"
    >
      <div className="circleContainer">
        <svg
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          style={{ overflow: "visible" }}
        >
          <path
            d="M0,20a20,20 0 1,0 40,0a20,20 0 1,0 -40,0"
            className={`fillcircle ${primary ? "fillcircle-primary" : ""}`}
            style={{ stroke: color }}
          />
        </svg>
      </div>
      {icons[type].icon}
    </a>
  );
};
export default SocialIcon;
