import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import Spinner from "@Components/common/Spinner";
import CirclesBackground from "@Components/CirclesBackground";
import Header from "@Components/layouts/Header";
import Footer from "@Components/layouts/Footer";
import HeadGenerator from "@Components/layouts/HeadGenerator";
import Modal from "@Components/common/Modal";
import CookieMsg from "@Components/CookieMsg";
import { WindowDimensionsProvider } from "@Services/contexts/WindowDimensionsContext";

const GlobalLayout = ({ children }) => {
  const router = useRouter();

  const [showModal, setShowModal] = useState(false);
  const [sidemenuOpen, setSidemenuOpen] = useState(false);

  const cookiesController = (status) => {
    localStorage.setItem("cookiesAccept", JSON.stringify(status));
    setShowModal(false);
  };

  useEffect(() => {
    const cookies = JSON.parse(localStorage.getItem("cookiesAccept"));

    if (!cookies) {
      setShowModal(true);
    }
  }, [showModal]);

  if (router.isFallback) {
    return (
      <>
        <HeadGenerator isFallback={true} />
        <WindowDimensionsProvider>
          <CirclesBackground />
          <Header open={sidemenuOpen} setOpen={setSidemenuOpen} />
          <Spinner show={true} />
          <Footer />
        </WindowDimensionsProvider>
      </>
    );
  }

  return (
    <>
      <HeadGenerator />
      <Modal
        showModal={showModal}
        showEsc={false}
        setShowModal={cookiesController}
        preventOutsideClick={true}
      >
        <CookieMsg setShowModal={cookiesController} />
      </Modal>
      <WindowDimensionsProvider>
        <CirclesBackground />
        <Header open={sidemenuOpen} setOpen={setSidemenuOpen} />
        <main>{children}</main>
        <Footer />
      </WindowDimensionsProvider>
    </>
  );
};

export default GlobalLayout;
