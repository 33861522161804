const checkWindowSize = (windowWidth) => {
  if (windowWidth <= 769) {
    return {
      isMobile: true,
      isTablet: false,
      isDesktop: false,
    };
  }
  if (windowWidth > 769 && windowWidth <= 1024) {
    return {
      isMobile: false,
      isTablet: true,
      isDesktop: false,
    };
  }
  if (windowWidth > 1024) {
    return {
      isMobile: false,
      isTablet: false,
      isDesktop: true,
    };
  }

  return {
    isMobile: null,
    isTablet: null,
    isDesktop: null,
  };
};

export default checkWindowSize;
