import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import Link from "next/link";

const options = [
  {
    id: "cloud-integration",
    name: "Cloud integration",
  },
  {
    id: "mobile-development",
    name: "Mobile Development",
  },
  {
    id: "post-development-maintenance",
    name: "Post-Development Maintenance",
  },
  {
    id: "product-design",
    name: "Product Design",
  },
  {
    id: "web-development",
    name: "Web development",
  },
];

const ServicesDropdown = () => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between items-center text-white font-[Spartan] font-bold text-2xl py-2 text-left focus:outline-none">
            <span>Services</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-8 w-8 text-white`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-white flex flex-col gap-4">
            {options.map((option) => (
              <Link
                key={option.id}
                href={`/services/${option.id}`}
                className="text-lg hover:underline"
              >
                {option.name}
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ServicesDropdown;
