import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import { MouseSmooth } from "react-mouse-smooth";
import checkWindowSize from "@Utils/checkWindowSize";

const WindowDimensionsContext = createContext({});

export const WindowDimensionsProvider = ({ children }) => {
  const globalWrapper = useRef();
  const [pageHeight, setPageHeight] = useState();
  const [pageWidth, setPageWidth] = useState();
  const [pageType, setPageType] = useState();
  useEffect(() => {
    if (globalWrapper.current) {
      const resizeObserver = new ResizeObserver((wrapper) => {
        setPageHeight(wrapper[0].contentRect.height);
        setPageWidth(wrapper[0].contentRect.width);
        setPageType(checkWindowSize(wrapper[0].contentRect.width));
      });

      resizeObserver.observe(globalWrapper.current);
    }
  }, []);

  let isMounted = false;

  useEffect(() => {
    if (isMounted) {
      return;
    }
    const smoothScrolling = () => {
      if (pageType && !pageType.isMobile) {
        MouseSmooth({});
      }
      isMounted = true;
    };
    window.addEventListener("load", smoothScrolling);
    return () => {
      window.removeEventListener("load", smoothScrolling);
    };
  }, [pageType]);
  return (
    <WindowDimensionsContext.Provider
      value={{ ...pageType, pageWidth, pageHeight }}
    >
      <div className="relative" ref={globalWrapper}>
        {children}
      </div>
    </WindowDimensionsContext.Provider>
  );
};
export const useWindowDimensions = () => useContext(WindowDimensionsContext);
