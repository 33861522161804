import {
  CloudIcon,
  DevicePhoneMobileIcon,
  ShieldCheckIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import Link from "next/link";
import { Menu } from "@headlessui/react";

const options = [
  {
    id: "cloud-integration",
    name: "Cloud integration",
    icon: <CloudIcon className="w-8 h-8" />,
  },
  {
    id: "mobile-development",
    name: "Mobile Development",
    icon: <DevicePhoneMobileIcon className="w-8 h-8" />,
  },
  {
    id: "post-development-maintenance",
    name: "Post-Development Maintenance",
    icon: <ShieldCheckIcon className="w-8 h-8" />,
  },
  {
    id: "product-design",
    name: "Product Design",
    icon: <CubeTransparentIcon className="w-8 h-8" />,
  },
  {
    id: "web-development",
    name: "Web development",
    icon: <GlobeAltIcon className="w-8 h-8" />,
  },
];

const ServicesMenu = () => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center gap-4">
        Services <ChevronDownIcon className={`h-5 w-5`} />
      </Menu.Button>

      <Menu.Items className="absolute top-14 right-0">
        <div className="w-[480px] grid grid-cols-2 grid-rows-3 gap-3 p-4 rounded-md bg-white shadow-md items-stretch justify-stretch">
          {options.map((option) => (
            <Menu.Item key={option.id}>
              <Link
                href={`/services/${option.id}`}
                className="bg-gradient-to-tr from-primary to-secondary hover:bg-gradient-to-r hover:from-secondary hover:to-secondary rounded-full p-2 pl-4 flex gap-4 items-center border-2 border-secondary"
              >
                <div className="flex-shrink-0 text-white">{option.icon}</div>
                <span className="text-sm text-white font-header">
                  {option.name}
                </span>
              </Link>
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default ServicesMenu;
